
import { computed, defineComponent, reactive, ref, Ref } from "vue";
import SignupPrivacyPolicyContentKR from "@/components/signup/SignupPrivacyPolicyContent_KR.vue";
import SignupForm from "@/components/signup/SignupForm";
import InputText from "@/components/primevueCustom/InputText.vue";
import Button from "primevue/button";
import { Field, Form, ErrorMessage } from "vee-validate";
import PageFrame from "@/components/common/PageFrame.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import Checkbox from "primevue/checkbox";
// import { onBeforeRouteLeave } from "vue-router";

const enum InnerPages {
  PRIVACYPOLICY,
  INPUTS,
}

export default defineComponent({
  name: "Signup",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const state = reactive({
      step: InnerPages.PRIVACYPOLICY,
      isPossibleEmail: false,
      isPossibleEmailButton: false,
    });

    const agreeAll = ref(false);

    const store = useStore();

    const privacyPolicyContent = SignupPrivacyPolicyContentKR;
    const signupForm = SignupForm;

    const signupForm$: Ref<SignupForm | null> = ref(null);

    const nextStep = () => {
      state.step += 1;
    };

    const isPrivacyPolicy = computed(
      () => state.step === InnerPages.PRIVACYPOLICY
    );

    // /**
    //  * 뒤로가기 시 가입화면이면 동의 화면으로 이동
    //  */
    // onBeforeRouteLeave((to, from, next) => {
    //   if (state.step > InnerPages.PRIVACYPOLICY) {
    //     state.step = InnerPages.PRIVACYPOLICY;
    //     agreeAll.value = false;
    //     next(false);
    //   } else next();
    // });

    return {
      state,
      alert,
      t,
      privacyPolicyContent,
      store,
      nextStep,
      agreeAll,
      signupForm,
      isPrivacyPolicy,
      signupForm$,
    };
  },
  methods: {
    submit() {
      this.signupForm$?.signup();
    },
  },

  components: {
    InputText,
    Button,
    Field,
    Form,
    ErrorMessage,
    PageFrame,
    Checkbox,
  },
});
