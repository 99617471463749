
import { computed, defineComponent, reactive, ref, Ref, watch } from "vue";
import InputText from "@/components/primevueCustom/InputText.vue";
import Button from "primevue/button";
import { Field, Form } from "vee-validate";
import $API from "@/services";
import { ISignupInfo } from "@/models/sign";
import { AxiosResponse } from "node_modules/axios";
import {
  ExceptionMessages,
  IAlert,
  IResponseData,
  ResultDatas,
  RouterName,
  SEX,
} from "@/models/common";
import InputMask from "primevue/inputmask";
import { useI18n } from "vue-i18n";
import { messageType } from "@/models/message";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { PossibleEmail } from "@/models/account";
import router from "@/router";
import Dropdown from "primevue/dropdown";
import { useConfirm } from "primevue/useconfirm";
import CustomConfirmDialog from "@/components/primevueCustom/ConfirmDialog.vue";

export default defineComponent({
  name: "Signup Form",

  setup() {
    const { t } = useI18n({ useScope: "global" });
    const state = reactive({
      step: 0,
      isPossibleEmail: false,
      isPossibleEmailButton: false,
    });

    const email = ref({
      emailName: "",
      emailProvider: "",
    });

    const selectedEmailProvider = ref({ name: "직접입력", code: "self" });
    const emailProviders = ref([
      { name: "직접입력", code: "self" },
      { name: "naver.com", code: "naver.com" },
      { name: "hanmail.net", code: "hanmail.net" },
      { name: "nate.conm", code: "nate.com" },
      { name: "gmail.com", code: "gmail.com" },
      { name: "kakao.com", code: "kakao.com" },
    ]);
    const isSelf = ref(true);

    watch(
      () => selectedEmailProvider.value,
      (n) => {
        if (n.code === "self") {
          email.value.emailProvider = "";
          isSelf.value = true;
        } else {
          email.value.emailProvider = n.code;
          isSelf.value = false;
        }
      }
    );

    // const error = ref("");
    const alert: Ref<IAlert> = ref({
      title: "회원가입",
      type: messageType.ERROR,
      content: "",
    });

    const inputData = reactive({
      password: "",
      passwordConfirm: "",
      familyName: "",
      firstName: "",
      email: computed(
        () => `${email.value.emailName}@${email.value.emailProvider}`
      ),
      birthDate: "",
      sex: "",
      mobilePhone: "",
    });

    watch(
      () => inputData.email,
      () => {
        const reg =
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (inputData.email.match(reg)) {
          setTimeout(() => {
            const $emailError = document.querySelector(".form__email .-error");
            if (!inputData.email) state.isPossibleEmailButton = false;
            else if ($emailError) state.isPossibleEmailButton = false;
            else state.isPossibleEmailButton = true;
          }, 10);

          if (state.isPossibleEmail) state.isPossibleEmail = false;
        }
      }
    );

    const store = useStore();

    const checkEmail = async () => {
      store.commit(CommonMutationTypes.SET_IS_LODING, true);
      try {
        const res: AxiosResponse<IResponseData<string>> =
          await $API.SignService.checkPossibleEmail(inputData.email);
        const {
          data: { ResultData },
        } = res;
        if (Array.isArray(ResultData)) {
          alert.value.content = "오류 발생, 잠시 후 다시 시도해주세요.";
          alert.value.type = messageType.ERROR;
          return;
        } else {
          if (ResultData === PossibleEmail.Possible) {
            alert.value.content = "사용 가능한 이메일";
            alert.value.type = messageType.INFO;
            state.isPossibleEmail = true;
          } else {
            alert.value.content = "이미 사용중인 이메일";
            alert.value.type = messageType.ERROR;
            state.isPossibleEmail = false;
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    };

    const signup = async () => {
      if (!state.isPossibleEmail) {
        alert.value = {
          content: "이메일 중복 확인을 해주세요.",
          type: messageType.ERROR,
        };

        return;
      }
      try {
        store.commit(CommonMutationTypes.SET_IS_LODING, true);
        const params: ISignupInfo = {
          Email: inputData.email,
          Password: inputData.password,
          FirstName: inputData.firstName,
          LastName: inputData.familyName,
          CountryCode: "ko",
          BirthDate: inputData.birthDate,
          Sex: inputData.sex,
          MobilePhone: inputData.mobilePhone,
        };

        const res: AxiosResponse<IResponseData<string>> =
          await $API.SignService.signup(params);
        const { data } = res;
        if (data.Exception === ExceptionMessages.FAILED) {
          // this.error = data.ResultData.toString();
          alert.value.content = data.ResultData.toString();
          alert.value.type = messageType.ERROR;
        } else if (!data.Exception && data.ResultData === ResultDatas.SUCCESS) {
          store.commit(CommonMutationTypes.SET_IS_LODING, false);
          signupSuccess();
        }
      } catch (e) {
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    };

    const nextStep = () => {
      state.step += 1;
    };

    const confirm = useConfirm();
    const signupSuccess = () => {
      confirm.require({
        header: "인증 메일이 발송되었습니다.",
        message: "완료됩니다.",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "확인",
        group: "emailSend",
        accept: () => {},
        reject: () => {
          const path = {
            name: RouterName.HOME,
          };
          router.push(path);
        },
      });
    };

    return {
      state,
      inputData,
      alert,
      t,
      store,
      checkEmail,
      signup,
      nextStep,
      email,
      selectedEmailProvider,
      emailProviders,
      isSelf,
      SEX,
      signupSuccess,
    };
  },

  components: {
    InputText,
    Button,
    Field,
    Form,
    Dropdown,
    InputMask,
    CustomConfirmDialog,
  },
});
