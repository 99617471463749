import { SEX } from "./common";

export interface IMyProfile{
  BirthDate:string,
  Email:string,
  FirstName:string,
  LastName:string,
  PhoneNumber:string,
  ReceiveEmail?:boolean | null | number,
  ReceiveSms?:boolean | null | number,
  Sex:SEX,
  UserId:string,
  UserName:string
  PharmacyKey?:number|null,
  RRN?:String|null
  isRRN:Number
}

export interface ISignInfo{
  accessToken:string,
}

export enum RoleNames{
  AllAdmins = "SysAdmins,Admins",
  SysAdmins = "Sysadmins",
  Admins = "Admins",
  Users = "Users",
  Coordinators ="Coordinators",
  Nurses="Nurses",
  Doctors="Doctors",
  AgencyRoles="Users,Coordinators",
  HospitalRoles="Coordinators,Nurses,Doctors"
}

export interface IAccountInfo{
  UserId:string,
  UserName:string,
  RoleName:RoleNames | "",
  Name:string,
  FacilityKey: number,
  PID:string,
  IsRRN:number
}

export enum PossibleEmail{
  Impossible = "impossible",
  Possible = "possible"
}