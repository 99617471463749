
import { defineComponent, onUpdated, reactive, ref, watch } from "vue";
import Checkbox from "primevue/checkbox";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "signup privacy policy KR",
  emits: ["update:agreeAll"],
  props: {
    agreeAll: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });

    const state = reactive({
      agree1: false,
      agree2: false,
      agree3: false,
    });
    const agreeAll = ref(props.agreeAll);

    watch(
      () => state,
      (n) => {
        emit("update:agreeAll", n.agree1 && n.agree2 && n.agree3);
      },
      { deep: true }
    );

    onUpdated(() => {
      if (props.agreeAll !== agreeAll.value) {
        agreeAll.value = props.agreeAll;
        state.agree1 = agreeAll.value;
        state.agree2 = agreeAll.value;
        state.agree3 = agreeAll.value;
      }
    });

    return { state, t };
  },
  components: {
    Checkbox,
  },
});
